<template>
  <div id="app">
    <div class="div_menu_bg" v-if="!isMobile ">
      <menu-navigation-bar />
    </div>
    <router-view />
    <div class="bottom_infor_fixed" v-if="!isMobile">
      <bottom-infor />
    </div>
  </div>
</template>
<script>
  import menuNavigationBar from "../src/components/menuNavigationBar/index.vue";
  import bottomInfor from "../src/components/bottomInfor/index.vue";
  export default {
    components: {
      menuNavigationBar,
      bottomInfor,
    },
    watch: {
      // 监听路由变化，决定是否显示头部
      $route(to) {
        console.log(to)
        // 可以根据不同的路由路径来判断是否显示头部
        if (to.path === '/no-header') {
          this.showHeader = false; // 如果是特定路径，则隐藏头部
        } else {
          this.showHeader = true; // 否则显示头部
        }
      }
    },

    data() {
      return {
        isMobile: false, // 默认不是手机端
        shouldShowHeaderAndFooter: true, // 控制是否显示头部尾部
      };
    },

    // computed: {
    //   showHeaderFooter() {
    //     return this.$route.meta.requiresHeaderFooter !== false;
    //   },
    // },
    created() {
      this.checkDeviceType();
      window.addEventListener("resize", this.checkDeviceType);
      this.checkPageForHeaderFooter();
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.checkDeviceType);
    },
    watch: {
      // 路由变化时重新判断是否显示头尾
      $route: "checkPageForHeaderFooter",
    },
    methods: {
      checkDeviceType() {
        this.isMobile = window.innerWidth <= 768; // 视为手机端的宽度限制
      },
      checkPageForHeaderFooter() {
        console.log(this.$route.path.includes("studentEditInfor"),11111111111111111111)
        // 判断当前路由是否为特定页面，如果是则不显示头尾
        // 假设 `/mobile` 页面不需要显示头尾
        this.shouldShowHeaderAndFooter = !this.$route.path.includes("studentEditInfor");
      },
    },
  };
</script>
<style>
  html,
  body {
    margin: 0;
    padding: 0;
    /* background:#292e3c; */
  }

  #app {
    margin: 0;
    padding: 0;
  }

  .div_menu_bg {
    position: absolute;
    width: 100%;
  }

  .bottom_infor_fixed {
    width: 100%;
    position: absolute;
  }
</style>
